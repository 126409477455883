
import { defineComponent, reactive, ref } from "vue";
import { storesManageService } from "@/service";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { RouterName } from "@/router";
import moment from "moment";

export default defineComponent({
  setup() {
    const router = useRouter();
    const active = ref(0);
    const store = useStore();

    const storeInfo = reactive<any>({
      data: {
        stores_name: "",
        logo_url: "",
        state_name: "",
        open_time_start: "",
        open_time_end: "",
        close_time_start: "",
        close_time_end: "",
        open_time: "",
        close_time: "",
        opt_lists: [],
        msg_arr: []
      },
      async getStoreInfo() {
        try {
          const { data } = await storesManageService.toOcStores(store.state.userInfo?.stores_id as string);
          storeInfo.data = data.data;
        } catch (error) {}
      },
      ocList: [],
      async getOcList() {
        try {
          const { data } = await storesManageService.storesOcLists({
            stores_id: store.state.userInfo?.stores_id,
            page: 1,
            pagesize: 9999999,
            // my_user_id: store.state.userInfo?.id
          });
          storeInfo.ocList = data.data;
        } catch (error) {}
      },
      delayLists: [],
      async getDelayLists() {
        try {
          const { data } = await storesManageService.storesDelayLists({
            stores_id: store.state.userInfo?.stores_id,
            page: 1,
            pagesize: 9999999
          });
          storeInfo.delayLists = data.data;
        } catch (error) {}
      }
    });
    storeInfo.getStoreInfo();
    storeInfo.getOcList();
    storeInfo.getDelayLists();

    const optClick = (type: number) => {
      switch (type) {
        // 开店检查
        case 1: {
          router.push({
            name: RouterName.OPEN_SHOP_INSPECT,
            query: {
              stores_id: storeInfo.data.stores_id,
              oc_type: storeInfo.data.oc_type
            }
          });
          break;
        }
        // 关店检查
        case 2: {
          router.push({
            name: RouterName.OPEN_SHOP_INSPECT,
            query: {
              stores_id: storeInfo.data.stores_id,
              oc_type: storeInfo.data.oc_type
            }
          });
          break;
        }
        // 待关店
        case 4: {
          const now = moment();
          const minDate = moment(`${now.format("YYYY-MM-DD")} ${storeInfo.data.close_time_end}`);
          minDate.add("1", "minutes");
          if (now.isAfter(moment(minDate), "minutes")) {
            minDate.add("1", "days");
          }
          const maxDate = moment(`${now.format("YYYY-MM-DD")} ${storeInfo.data.open_time_start}`);
          maxDate.subtract("1", "minutes");
          if (now.isAfter(moment(maxDate), "minutes")) {
            maxDate.add("1", "days");
          }

          router.push({
            name: RouterName.EXTENSION_APPLICATION,
            query: {
              stores_id: storeInfo.data.stores_id,
              oc_type: storeInfo.data.oc_type,
              minDate: minDate.format("x"),
              maxDate: maxDate.format("x")
            }
          });
          break;
        }
        // 待开店
        case 3: {
          const now = moment();
          const minDate = moment(`${now.format("YYYY-MM-DD")} ${storeInfo.data.open_time_end}`);
          minDate.add("1", "minutes");
          if (now.isAfter(moment(minDate), "minutes")) {
            minDate.add("1", "days");
          }
          const maxDate = moment(`${now.format("YYYY-MM-DD")} ${storeInfo.data.close_time_start}`);
          maxDate.subtract("1", "minutes");
          if (now.isAfter(moment(maxDate), "minutes")) {
            maxDate.add("1", "days");
          }

          router.push({
            name: RouterName.EXTENSION_APPLICATION,
            query: {
              stores_id: storeInfo.data.stores_id,
              oc_type: storeInfo.data.oc_type,
              minDate: minDate.format("x"),
              maxDate: maxDate.format("x")
            }
          });
          break;
        }
        default:
          break;
      }
    };

    return {
      active,
      storeInfo,
      optClick,
      toOCdetail(item: any) {
        router.push({
          name: RouterName.OPEN_CLOSE_SHOP_APPLY_DETAILS,
          query: {
            id: item.id
          }
        });
      },

      toDelayDetail(item: any) {
        router.push({
          name: RouterName.DELAY_APPLY_DETAILS,
          query: {
            id: item.id
          }
        });
      },

      stateClass: {
        "10": "open_tag",
        "11": "wait_tag",
        "12": "delay_tag",
        "13": "overtime_tag",
        "20": "stop_tag",
        "21": "wait_tag",
        "22": "delay_tag",
        "23": "overtime_tag",
        "40": "stop_tag"
      },
      tagClass: {
        "1": "blue-tag",
        "2": "orange-tag",
        "3": "green-tag",
        "4": "gray-tag",
        "5": "gray-tag"
      }
    };
  }
});
